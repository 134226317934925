import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class NeedPermissionGuard  {
  private readonly redirectUrl = '/';

  constructor(
    private router: Router,
    private auth: AuthService,
    public snackBar: MatSnackBar
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    const neededPermission = route.data['neededPermission'] as string;
    return this.auth.userHasPermission(neededPermission).pipe(
      map((canAcess) => {
        if (canAcess) {
          return true;
        }
        this.snackBar.open('You are not authorized to go there.', 'OK', {
          duration: 3000,
          panelClass: 'error-snack-bar',
        });
        return this.router.parseUrl(this.redirectUrl);
      })
    );
  }
}
